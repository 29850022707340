<ng-container *ngIf="datasetsSearchResults.length > 0; else noResults">
  <div class="results">
    <section class="results-all">
      <mat-checkbox
        color="primary"
        [(ngModel)]="areAllResultsInBasket"
        (change)="onAllDatasetsToggled()"
        [matTooltip]="
          areAllResultsInBasket
            ? 'Remove all displayed datasets'
            : 'Select all displayed datasets'
        "
      >
      </mat-checkbox>
      <i class="results-amount">
        {{ resultAmountDisplay }}
      </i>
    </section>
    <section class="result" *ngFor="let dataset of datasetsSearchResults">
      <mat-checkbox
        color="primary"
        [checked]="dataset.isInBasket"
        (change)="onDatasetToggled(dataset)"
        [matTooltip]="
          dataset.isInBasket ? 'Remove from basket' : 'Add to basket'
        "
      >
      </mat-checkbox>
      <app-dataset-summary
        [dataset]="dataset"
        [showDetails]="showResultsDetails"
        [showGeneralComment]="showResultsDetails"
      ></app-dataset-summary>
    </section>
  </div>
</ng-container>
<ng-template #noResults>
  <div *ngIf="firstQuerySent" class="no-results">
    <p fxLayoutAlign="start center">
      <mat-icon class="disabled-text-color">search_off</mat-icon>
      Your search did not match any datasets.
    </p>
  </div>
  <div *ngIf="suggestion !== undefined" class="no-results">
    Instead of <b>{{ suggestion.originalText }}</b
    >, did you mean ?
    <mat-chip-listbox>
      <mat-chip-option
        *ngFor="let suggestionOption of suggestionOptions"
        [removable]="false"
        [selectable]="true"
        (click)="
          onSuggestionSelection({
            originalText: suggestion.originalText,
            suggestion: suggestionOption
          })
        "
        >{{ suggestionOption }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { DatasetSearchApiService } from '@app/modules/dataset/api/dataset-search-api.service';
import {DatasetSearchRequest, DatasetSearchResponse} from '@app/modules/dataset/models/dataset-search.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatasetSearchResultMapperService } from '../mappers/dataset-search-result/dataset-search-result-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchService {
  constructor(
    private datasetSearchApiService: DatasetSearchApiService,
    private datasetSearchResultMapper: DatasetSearchResultMapperService
  ) {}

  searchDatasets(searchRequest: DatasetSearchRequest
  ): Observable<DatasetSearchResponse> {

    const [isicSection, isicDivision, isicGroup, isicCode] = distributeIsics(searchRequest.filters.isics || []);
    return this.datasetSearchApiService
      .searchDatasets(
        searchRequest.searchTerm,
        searchRequest.filters.geographies,
        searchRequest.filters.databases,
        isicSection, isicDivision, isicGroup, isicCode,
        [],
        searchRequest.filters.activityTypes,
        searchRequest.filters.units)
      .pipe(
        map((datasets) => this.datasetSearchResultMapper.fromResults(datasets))
      );
  }
}


export function distributeIsics(isics: string[]): string[][] {
  const lengthMap = new Map<number,string[]>();
  isics.forEach(c => {
    const current = lengthMap.get(c.length) || [];
    current.push(c);
    lengthMap.set(c.length, current);
  });
  return [
    lengthMap.get(1) || [],
    lengthMap.get(2) || [],
    lengthMap.get(3) || [],
    lengthMap.get(4) || []
  ]
}

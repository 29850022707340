import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersGroupComponent } from '@app/modules/ui/modules/filters-group/components/filters-group/filters-group.component';
import { FilterComponent } from '@app/modules/ui/modules/filters-group/components/filter/filter.component';
import { ChipsGroupComponent } from '@app/modules/ui/modules/filters-group/components/chips-group/chips-group.component';
import { MaterialModule } from '@app/modules/material/material.module';
import { SelectModule } from '@app/modules/ui/modules/select/select.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgStringPipesModule } from 'ngx-pipes';
import {
  ChipsContainerComponent
} from "@app/modules/ui/modules/filters-group/components/chips-container/chips-container.component";

@NgModule({
  declarations: [FiltersGroupComponent, FilterComponent, ChipsGroupComponent, ChipsContainerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    SelectModule,
    NgStringPipesModule,
  ],
  exports: [FiltersGroupComponent, ChipsGroupComponent, ChipsContainerComponent],
})
export class FiltersGroupModule {}

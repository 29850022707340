import {
  DatasetDescription,
} from '@app/modules/dataset/models/dataset.model';
import { Classification } from '@app/modules/reference-data/modules/classification/models/classification.model';
import { AssessmentGroup } from '@app/modules/dataset/models/assessment.model';
import { IndicatorGroup } from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';
import {MethodIndicator} from "@app/modules/reference-data/modules/method/models/method.model";
import {Contribution, ContributionTypeName} from "@app/modules/dataset/modules/contribution/model/contribution.model";

export function getWarnings(
  datasetDescription: DatasetDescription,
): Classification[] {
  const classifications = datasetDescription.classifications;
  return classifications?.get('WARNING') || [];
}

export function isIndicatorGroupsMatchingAssessmentGroups(
  indicatorGroups: Array<IndicatorGroup>,
  assessmentGroups: Array<AssessmentGroup>,
): boolean {
  const assessmentGroupsNames = extractNames(assessmentGroups);
  return indicatorGroups.every((indicatorGroup) =>
    assessmentGroupsNames.includes(indicatorGroup.name),
  );
}

export function extractIndicatorGroupsMatchingAssessmentGroups(
  indicatorGroups: Array<IndicatorGroup>,
  assessmentGroups: Array<AssessmentGroup>,
): Array<IndicatorGroup> {
  const assessmentGroupsNames = extractNames(assessmentGroups);
  return indicatorGroups.filter((indicatorGroup) =>
    assessmentGroupsNames.includes(indicatorGroup.name),
  );
}

export function extractAssessmentGroupsMatchingIndicatorGroups(
  indicatorGroups: Array<IndicatorGroup>,
  assessmentGroups: Array<AssessmentGroup>,
): Array<AssessmentGroup> {
  const indicatorGroupsNames = extractNames(indicatorGroups);
  return assessmentGroups.filter((assessmentGroup) =>
    indicatorGroupsNames.includes(assessmentGroup.name),
  );
}
export function exactMethodIndicatorMatching(groupName: string, assessmentGroups: Array<AssessmentGroup>) : Array<MethodIndicator> {
  return assessmentGroups
    .filter((g) => g.name === groupName)
    .flatMap((g) => g.flattenContributions)
    .map((f) => f.indicator)
    .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
}

export function extractContributionTypeMatchingIndicator(indicator: MethodIndicator | null, flattenedContributions: Array<Contribution>) : Array<ContributionTypeName> {
    return [
      ...new Set(
        flattenedContributions
          .filter((c) => c.indicator.id === indicator?.id)
          .filter((c) => c.type !== 'ACTIVITY_CATEGORY')
          .map((c) => c.type)
      ),
    ]
}

function extractNames(array: Array<{ name: string }>): Array<string> {
  return array.map((el) => el.name);
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  DatasetSearchResult, DatasetSearchResponse, Suggestion,
  SuggestionSelection,
} from '@app/modules/dataset/models/dataset-search.model';
import {
  addAllDatasets,
  addDataset,
  removeAllDatasets,
  removeDataset,
} from '@app/modules/basket/store/dataset/basket-dataset.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import {combineLatest, Observable, Subject} from "rxjs";
import {selectDatasetsIds} from "@app/modules/basket/store/dataset/basket-dataset.selectors";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-datasets-search-results',
  templateUrl: './datasets-search-results.component.html',
  styleUrl: './datasets-search-results.component.scss',
})
export class DatasetsSearchResultsComponent implements OnInit, OnDestroy {

  @Input()
  searchResponse$!: Observable<DatasetSearchResponse>;

  @Input()
  showResultsDetails: boolean = false;

  @Input()
  firstQuerySent: boolean = false;

  @Output()
  suggestionSelection = new EventEmitter<SuggestionSelection>();

  onDestroy$ = new Subject<void>();

  // Enriched results (with basket labelling)
  datasetsSearchResults: DatasetSearchResult[] = [];
  areAllResultsInBasket: boolean = false;
  totalDocCount: number = 0;
  maxDocCount: number = 0;
  suggestion?: Suggestion;
  suggestionOptions: string[] = [];

  get resultAmountDisplay(): string {
    if (this.totalDocCount === 1) {
      return '1 result found';
    } else {
      if (this.totalDocCount > this.maxDocCount) {
        return `${this.totalDocCount} results found (${this.maxDocCount} displayed)`;
      } else {
        return `${this.totalDocCount} results found`;
      }
    }
  }

  constructor(private store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    combineLatest([
      this.searchResponse$,
      this.store.select(selectDatasetsIds()),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([searchResponse,ids]) => {
        const basketIds = new Set(ids);
        this.totalDocCount = searchResponse.totalDocCount;
        this.maxDocCount = searchResponse.maxDocCount;
        this.suggestion = searchResponse.suggestion;
        this.suggestionOptions = searchResponse.suggestion?.suggestions || [];
        this.datasetsSearchResults = searchResponse.results.map(
          (dataset: DatasetSearchResult) =>
            dataset.copyWith({
              isInBasket: basketIds.has(dataset.id),
            }),
        );
        this.areAllResultsInBasket = this.datasetsSearchResults.every(x => x.isInBasket);
      })
  }

  onDatasetToggled(dataset: DatasetSearchResult): void {
    if (!dataset.isInBasket) {
      this.addToBasket(dataset);
    } else {
      this.removeFromBasket(dataset);
    }
  }

  onAllDatasetsToggled(): void {
    if (this.areAllResultsInBasket) {
      this.addAllToBasket(this.datasetsSearchResults);
    } else {
      this.removeAllFromBasket(this.datasetsSearchResults);
    }
  }

  private addToBasket(dataset: DatasetSearchResult): void {
    this.store.dispatch(addDataset({ dataset }));
  }

  private addAllToBasket(datasets: DatasetSearchResult[]): void {
    this.store.dispatch(addAllDatasets({ datasets }));
  }

  private removeFromBasket(dataset: DatasetSearchResult): void {
    this.store.dispatch(removeDataset({ dataset }));
  }

  private removeAllFromBasket(datasets: DatasetSearchResult[]): void {
    this.store.dispatch(removeAllDatasets({ datasets }));
  }

  onSuggestionSelection(suggestionSelection: SuggestionSelection): void {
    this.suggestionSelection.emit(suggestionSelection);
  }
}

import {DropDownMenuItem} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.model";
import {LabeledFacet} from "@app/modules/dataset/models/dataset-search.model";

export interface DatasetSearchDropDowns {
  geographies: DropDownMenuItem[]
  databases: DropDownMenuItem[]
  activityTypes: DropDownMenuItem[]
  units: DropDownMenuItem[]
  isics: DropDownMenuItem[]
}

export interface DatasetSearchFacets {
  geographies?: LabeledFacet
  databases?: LabeledFacet
  activityTypes?: LabeledFacet
  units?: LabeledFacet
  isics?: LabeledFacet
}

export type Criteria<T> = {
  databases: T,
  geographies: T,
  activityTypes: T,
  units: T,
  isics: T
}

export type DatasetSearchCriteria = keyof Criteria<unknown>;

export const DATASET_SEARCH_CRITERIA: DatasetSearchCriteria[] = [
  "geographies",
  "databases",
  "activityTypes",
  "units",
  "isics"
]

import { ActivityTypeDto } from '@app/api/__generated__/model/activityTypeDto';

export type ActivityType = {
  value: ActivityTypeDto;
  name: string
}

export const ACTIVITY_TYPES: Array<ActivityType> = [
  {
    value: 'MARKET_ACTIVITY',
    name: 'Market activity',
  },
  {
    value: 'MARKET_GROUP',
    name: 'Market group',
  },
  {
    value: 'ORDINARY_TRANSFORMING_ACTIVITY',
    name: 'Transforming activity',
  },
  {
    value: 'PRODUCTION_MIX',
    name: 'Production mix',
  },
];

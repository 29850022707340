export const environment = {
  name: 'development',
  enableSentry: true,
  auth0: {
    domain: 'dataplatform-dev.eu.auth0.com',
    clientId: 'ydGWWrWRbqKiICsPGsfhk6BUNwdLYnsK',
    audience: 'https://eqosphere',
  },
  rum: {
    identityPoolId: '',
    appMonitorId: '',
  },
  logoutUrl: 'https://dev.dataplatform.quantis.solutions',
  apiUrl: 'https://api.dev.dataplatform.quantis.solutions',
  eQoterraUrl: 'https://dev.eqofarm.quantis.solutions',
  enableRum: false,
  defaultRoutes: [
    {
      linkTo: '/datasets',
      label: 'Access',
      type: 'RELATIVE',
      allowNonCustom: true,
    },
    {
      linkTo: 'https://dev.eqofarm.quantis.solutions/activity-modeling',
      label: 'Custom',
      type: 'ABSOLUTE',
      allowNonCustom: false,
    },
    {
      linkTo: '/characterization-factors',
      label: 'Characterization factors',
      type: 'RELATIVE',
      allowNonCustom: true,
    }]
};

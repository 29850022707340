import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Chip, ChipGroup} from "@app/modules/ui/modules/filters-group/components/chips-container/chips-container.model";

@Component({
  selector: 'app-chips-container',
  templateUrl: './chips-container.component.html',
  styleUrl: './chips-container.component.scss',
})
export class ChipsContainerComponent {

  @Input()
  set chipGroups(newGroups: ChipGroup[]) {
    this._chipGroups = newGroups;
    this.count = this._chipGroups.reduce((sum, group) => sum + group.items.length, 0);
  }

  @Output()
  chipGroupChange = new EventEmitter<ChipGroup>();

  @Output()
  clearChips = new EventEmitter<void>();

  protected count: number = 0;
  protected _chipGroups: ChipGroup[] = [];

  removeChipFromGroup(group: ChipGroup, chip: Chip): void {
    const updatedGroup = { key: group.key, label: group.label, items: group.items.filter(i => i.value !== chip.value)};
    this.chipGroupChange.emit(updatedGroup)
  }
}

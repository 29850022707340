import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from '@angular/material/tree';
import { of as ofObservable, Observable } from 'rxjs';
import {LabeledFacetBucket, LabeledFacet} from "@app/modules/dataset/models/dataset-search.model";
import {FacetItemFlatNode, FacetItemNode, FacetTreeState} from "@app/modules/ui/components/facet-tree/facet-tree.model";

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-facet-tree',
  templateUrl: './facet-tree.component.html',
  styleUrl: './facet-tree.component.scss'
})
export class FacetTreeComponent {
  private _facet: LabeledFacet | undefined = undefined;
  private facetTreeState = new FacetTreeState();

  @Input()
  set facet(facet: LabeledFacet) {
    this._facet = facet;
    this.update();
  }

  @Input()
  set selection(values: string[]) {
    this.facetTreeState.selection = new Set(values);
  }

  @Output()
  changed = new EventEmitter<string[]>();

  valueNodeMap = new Map<string, FacetItemFlatNode>();

  treeControl: FlatTreeControl<FacetItemFlatNode>;
  treeFlattener: MatTreeFlattener<FacetItemNode, FacetItemFlatNode>;
  dataSource: MatTreeFlatDataSource<FacetItemNode, FacetItemFlatNode>;

  constructor() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FacetItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  getLevel: (node: FacetItemFlatNode) => number = (node: FacetItemFlatNode) => {
    return node.level;
  };

  isExpandable: (node: FacetItemFlatNode) => boolean = (node: FacetItemFlatNode) => {
    return node.expandable;
  };

  getChildren = (node: FacetItemNode): Observable<FacetItemNode[]> => {
    return ofObservable(node.children);
  };

  hasChild: (_: number, _nodeData: FacetItemFlatNode) => boolean = (_: number, _nodeData: FacetItemFlatNode) => {
    return _nodeData.expandable;
  };

  transformer: (node: FacetItemNode, level: number) => FacetItemFlatNode = (node: FacetItemNode, level: number) => {
    const flatNode =
      this.valueNodeMap.has(node.item)
        ? this.valueNodeMap.get(node.item)!
        : new FacetItemFlatNode();
    flatNode.item = node.item;
    flatNode.label = node.label;
    flatNode.percent = node.percent;
    flatNode.count = node.count;
    flatNode.level = level;
    flatNode.expandable = node.children.length > 0;
    node.children.forEach(c => this.facetTreeState.registerChild(node.item, c.item));
    this.valueNodeMap.set(node.item, flatNode);
    return flatNode;
  };

  isPartiallySelected(node: FacetItemFlatNode): boolean {
    return this.facetTreeState.isPartiallySelected(node.item);
  }

  private nodeOfBucket(bucket: LabeledFacetBucket, totalDocs: number): FacetItemNode {
    const children = this.nodesOfFacet(bucket.facets?.[0], totalDocs);
    return {
      item: bucket.bucketName,
      label: bucket.label,
      count: bucket.docCount,
      percent: bucket.docCount / totalDocs,
      children: children
    };
  }

  private nodesOfFacet(facet: LabeledFacet | undefined, totalDocs: number): FacetItemNode[] {
    if((facet?.buckets?.length || 0)> 0) {
      return facet!.buckets.map(b => this.nodeOfBucket(b, totalDocs));
    }
    return [];
  }

  private update() {
    if(this._facet) {
      const totalDocs = this._facet.buckets.reduce((sum, bucket) => sum + bucket.docCount, 0);
      this.dataSource.data = this._facet.buckets.map(b => this.nodeOfBucket(b, totalDocs));
    }
  }

  onCheckToggle(node: FacetItemFlatNode):void {
    const updatedSelection = this.facetTreeState.toggleItem(node.item);
    this.changed.emit(updatedSelection);
  }

  isSelected(node: FacetItemFlatNode): boolean {
    return this.facetTreeState.isSelected(node.item);
  }
}

<div class="sidebar">
  <div fxLayout="column" fxLayoutAlign="space-around stretch">
    <div *ngIf="isicFacet !== undefined && isicFacet.buckets.length > 0">
      <h3>Filter by ISIC</h3>
      <app-facet-tree
        [facet]="isicFacet"
        [selection]="searchRequest.filters.isics"
        (changed)="isicSelectionChange.emit($event)"
      ></app-facet-tree>
    </div>
  </div>
</div>


.contribution {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  min-width: 8em;
}

.amount {
  white-space: nowrap;
  min-width: 6em;
}

td.unit {
  min-width: 3em;
}

.allocation {
  min-width: 6em;
}

.exchanges .label {
  min-width: 20em;
}

import {Injectable} from "@angular/core";
import {GeographyService} from "@app/modules/reference-data/modules/geography/services/geography.service";
import {UnitService} from "@app/modules/reference-data/modules/unit/services/unit.service";
import {
  ClassificationService
} from "@app/modules/reference-data/modules/classification/services/classification.service";
import {combineLatest, Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {
  ACTIVITY_TYPES,
  ActivityType
} from "@app/modules/reference-data/modules/activity-type/models/activity-types.model";
import {Database} from "@app/modules/reference-data/modules/database/models/database.model";
import {DropDownMenuItem} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.model";
import {GeographyWithDisplayName} from "@app/modules/dataset/models/dataset-search.model";
import {Unit} from "@app/modules/reference-data/modules/unit/models/unit.model";
import {Classification} from "@app/modules/reference-data/modules/classification/models/classification.model";
import {DatasetSearchDropDowns} from "@app/modules/dataset/pages/datasets-search/datasets-search.model";
import {DatabaseService} from "@app/modules/reference-data/modules/database/services/database.service";

@Injectable({
  providedIn: 'root',
})
export class DatasetDropdownService {
  constructor(
    private databaseService: DatabaseService,
    private geographyService: GeographyService,
    private unitService: UnitService,
    private isicsService: ClassificationService
  ) {

  }

  getDropdowns(): Observable<DatasetSearchDropDowns> {
    return combineLatest(
      [
        this.databaseService.findSortedDatabases(),
        this.geographyService.findGeographiesWithDisplayNameAndSort(),
        this.unitService.findUnitsAndSort(),
        this.isicsService.findIsicClassifications()
      ]
    ).pipe(
      take(1), // We only fetch this data once
      map(([databases, geographies, units, isics]) => {
        return {
          databases: databases.map(menuItemOfDatabase),
          geographies: geographies.map(menuItemOfGeography),
          activityTypes: ACTIVITY_TYPES.map(menuItemOfActivityType),
          units: units.map(menuItemOfUnit),
          isics: isics.map(menuItemOfClassification)
        } as DatasetSearchDropDowns;
      }));
  }
}

function menuItemOfDatabase(item: Database): DropDownMenuItem {
  return {
    group: item.status,
    label: item.displayName,
    value: item.key,
    search: item.simpleDisplayName
  };
}

function menuItemOfGeography(item: GeographyWithDisplayName): DropDownMenuItem {
  return {
    label: item.displayName,
    value: item.shortName,
    search: item.displayName
  };
}

function menuItemOfActivityType(a : ActivityType): DropDownMenuItem {
  return {
    label: a.name,
    value: a.value,
    search: a.name
  }
}

function menuItemOfUnit(item: Unit): DropDownMenuItem {
  return {
    label: item.formattedName,
    value: item.name,
    search: item.formattedName
  };
}

function menuItemOfClassification(item: Classification): DropDownMenuItem {
  return {
    label: item.description,
    value: item.code,
    search: item.displayDescription
  };
}

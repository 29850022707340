export class FacetItemNode {
  children: FacetItemNode[] = [];
  item: string = "";
  label: string = "";
  percent: number = 0;
  count: number = 0;
}

export class FacetItemFlatNode {
  item: string = "";
  label: string = "";
  level: number = 0;
  percent: number = 0;
  count: number = 0;
  expandable: boolean = false;
}


export class FacetTreeState {
  selection: Set<string> = new Set();
  parentMap: Map<string, string> = new Map();
  childMap: Map<string, string[]> = new Map();

  isSelected(item: string): boolean {
    const ancestors = this.ancestorsOf(item);
    return ancestors.some(a => this.selection.has(a));
  }

  ancestorsOf(item: string): string[] {
    const ancestors = new Array<string>();
    let ancestor: string | undefined = item;
    while(ancestor) {
      ancestors.push(ancestor);
      ancestor = this.parentMap.get(ancestor);
    }
    return ancestors;
  }

  private childrenOf(item: string): string[] {
    return this.childMap.get(item) || [];
  }

  descendantsOf(item: string): string[] {
    return this.childrenOf(item).concat(this.childrenOf(item).flatMap((x) => this.descendantsOf(x)));
  }

  toggleItem(item: string): string[] {
    if(this.selection.has(item)) {
      // We were explicitly selected so now we are not
      this.selection.delete(item);
    } else {
      // We were not explicitly selected: maybe not selected at all or covered by an ancestor
      const ancestors = this.ancestorsOf(item);
      let found: number | undefined = undefined;
      for(let i=0;i<ancestors.length && found === undefined; i++) {
        if(this.selection.has(ancestors[i])) {
          found = i;
        }
      }
      if(found) {
        this.selection.delete(ancestors[found]);
        for(let i= 1;i<=found; i++) {
          this.childrenOf(ancestors[i]).filter(x => !ancestors.includes(x)).forEach(x => this.selection.add(x));
        }
      } else {
        this.selection.add(item);
        this.descendantsOf(item).forEach(c => this.selection.delete(c));
        let parent = this.parentMap.get(item);
        while(parent) {
          const siblings = this.childrenOf(parent);
          if(siblings.every(x => this.selection.has(x))) {
            siblings.forEach(x => this.selection.delete(x));
            this.selection.add(parent);
            parent = this.parentMap.get(parent)
          } else {
            parent = undefined;
          }
        }
      }
    }
    return [...this.selection];
  }

  isPartiallySelected(item: string): boolean {
    return !this.selection.has(item) && this.descendantsOf(item).some(d => this.selection.has(d)) || false;
  }

  registerChild(parent: string, child: string): void {
    this.parentMap.set(child, parent);
    const updatedChildren = (this.childMap.get(parent) || []);
    updatedChildren.push(child);
    this.childMap.set(parent, updatedChildren);
  }
}

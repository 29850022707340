
<div class="filter-chips" fxLayoutAlign="start center" fxLayout="row wrap" *ngIf="_chipGroups.length > 0">
  <ng-container *ngFor="let group of _chipGroups">
    <app-chips-group
      [items]="group.items"
      [groupName]="group.label"
      displayField="label"
      (removed)="removeChipFromGroup(group, $event)"
    ></app-chips-group>
  </ng-container>
  <button *ngIf="count > 1"
          mat-button
          mat-stroked-button
          class="clear-all"
          (click)="clearChips.emit()"
  >
    Clear all
    <mat-icon>cancel</mat-icon>
  </button>
</div>

import {Component, Input} from '@angular/core';
@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
})
export class ProgressBarComponent {
  progressWidth: string = "0%";

  @Input()
  set progress(ratio: number) {
    this.progressWidth = ratio * 100 + '%';
  }
}

import {Cloneable} from '@app/modules/dataset/models/clonable.model';
import {DatasetDescription, ImpactAssessmentSample,} from '@app/modules/dataset/models/dataset.model';
import {Geography} from '@app/modules/reference-data/modules/geography/models/geography.model';
import {BucketKey} from "@app/modules/dataset/services/dataset-search-mapper.service";
import {Criteria} from "@app/modules/dataset/pages/datasets-search/datasets-search.model";

export const defaultDatasetSearchFilters = (): DatasetSearchFilters => ({
  databases: ['ecoinvent:3.10', 'WFLDB:3.10', 'WALDB:2.6',
    'MARS:2.0', 'General Mills:1.1','Kenvue:1.0'],
  geographies: [],
  activityTypes: [],
  units: [],
  isics: [],
});

export type DatasetQueryParams = {
  q: string;
  database: Array<string>;
  geography: Array<string>;
  activityType: Array<string>;
  unit: Array<string>;
  isic: Array<string>;
};

export type DatasetSearchRequest = {
  searchTerm: string;
  filters: DatasetSearchFilters;
};

export type DatasetSearchRequestPatch = {
  searchTerm?: string;
  filters?: Partial<DatasetSearchFilters>;
};

export type DatasetSearchFilters = {
  databases: string[];
  geographies: string[];
  activityTypes: string[];
  units: string[];
  isics: string[];
};

export const NO_FILTERS: Criteria<string[]>  = {
  databases: [],
  geographies: [],
  activityTypes: [],
  units: [],
  isics: []
};

export type SearchFilterOptionMap = {
  [key: string]: KeyValueGroupMap;
};

export type KeyValueGroupMap = {
  [key: string]: { value: string; group?: string };
};

export type KeyValueCount = {
  key: string;
  value: string;
  docCount?: number;
};

export type KeyValueCountGroup = {
  key: string;
  value: string;
  docCount?: number;
  group: string;
  filteredAttributes?: string[];
};

export type KeyCountMap = {
  [key: string]: number;
};

export type DatasetSearchFiltersOptions = {
  databases: KeyValueCountGroup[];
  geographies: KeyValueCount[];
  activityTypes: KeyValueCount[];
  units: KeyValueCount[];
  isics: KeyValueCount[];
};

export type FacetBucket = {
  bucketName: string;
  docCount: number;
  facets?: Facet[];
};

export type LabeledFacetBucket = FacetBucket & {
  label: string,
  facets?: LabeledFacet[],
}

export type Facet = {
  key: BucketKey;
  buckets: FacetBucket[],
};

export type LabeledFacet = {
  key: BucketKey,
  buckets: LabeledFacetBucket[],
}

export type DatasetSearchResponse = {
  results: DatasetSearchResult[];
  facets: Facet[];
  suggestion?: Suggestion;
  totalDocCount: number;
  maxDocCount: number;
};

export type Suggestion = {
  originalText: string;
  suggestions: string[];
};

export type SuggestionSelection = {
  originalText: string;
  suggestion: string;
};

export type GeographyWithDisplayName = Geography & {
  displayName: string;
};

export class DatasetSearchResult extends Cloneable {
  id: string;
  name: string;
  unit: string;
  description: DatasetDescription;
  groups: string[];
  impactIndicatorSamples: Array<ImpactAssessmentSample>;
  isInBasket: boolean;

  constructor(
    id: string,
    name: string,
    unit: string,
    description: DatasetDescription,
    groups: string[],
    impactIndicatorSamples: Array<ImpactAssessmentSample>,
  ) {
    super();
    this.id = id;
    this.name = name;
    this.unit = unit;
    this.description = description;
    this.groups = groups;
    this.impactIndicatorSamples = impactIndicatorSamples;
    this.isInBasket = false;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatasetSummaryComponent } from '@app/modules/dataset/components/dataset-summary/dataset-summary.component';
import { DatasetsSearchComponent } from '@app/modules/dataset/pages/datasets-search/datasets-search.component';
import { DatasetRoutingModule } from '@app/modules/dataset/dataset-routing.module';
import { DatasetDetailModule } from '@app/modules/dataset/modules/dataset-detail/dataset-detail.module';
import { ImpactFactorModule } from '@app/modules/dataset/modules/impact-factor/impact-factor.module';
import { MaterialModule } from '@app/modules/material/material.module';
import { ReferenceDataModule } from '@app/modules/reference-data/reference-data.module';
import { UiModule } from '@app/modules/ui/ui.module';
import { DatasetWarningModule } from '@app/modules/dataset/modules/dataset-warning/dataset-warning.module';
import { SearchTipsDialogComponent } from './components/search-tips-dialog/search-tips-dialog.component';
import { DatasetsSearchResultsComponent } from '@app/modules/dataset/components/datasets-search-results/datasets-search-results.component';
import { DatasetsSearchFormComponent } from '@app/modules/dataset/components/datasets-search-form/datasets-search-form.component';
import { DatasetsSearchSidebarComponent } from '@app/modules/dataset/components/datasets-search-sidebar/datasets-search-sidebar.component';
import { NgStringPipesModule } from 'ngx-pipes';

@NgModule({
  declarations: [
    DatasetSummaryComponent,
    DatasetsSearchComponent,
    SearchTipsDialogComponent,
    DatasetsSearchResultsComponent,
    DatasetsSearchFormComponent,
    DatasetsSearchSidebarComponent,
  ],
  exports: [DatasetSummaryComponent, DatasetsSearchComponent],
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        MaterialModule,
        ImpactFactorModule,
        FormsModule,
        ReferenceDataModule,
        UiModule,
        NgStringPipesModule,
        ReactiveFormsModule,
        DatasetDetailModule,
        DatasetWarningModule,
        DatasetRoutingModule, // must be imported as the last module
    ],
})
export class DatasetModule {}

import {Component, EventEmitter, Input, Output,} from '@angular/core';
import {DatasetSearchRequest, LabeledFacet} from '@app/modules/dataset/models/dataset-search.model';
import {DatasetSearchFacets} from "@app/modules/dataset/pages/datasets-search/datasets-search.model";

@Component({
  selector: 'app-datasets-search-sidebar',
  templateUrl: './datasets-search-sidebar.component.html',
  styleUrl: './datasets-search-sidebar.component.scss',
})
export class DatasetsSearchSidebarComponent
{
  @Input()
  set facets(facets: DatasetSearchFacets) {
    this.isicFacet = facets.isics;
  }

  @Input()
  searchRequest!: DatasetSearchRequest;

  @Output()
  isicSelectionChange = new EventEmitter<string[]>

  isicFacet: LabeledFacet | undefined;

  protected readonly JSON = JSON;

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  DatasetSearchFilters,
  DatasetSearchRequest, DatasetSearchRequestPatch,
  DatasetSearchResponse, defaultDatasetSearchFilters,
  Facet,
  FacetBucket,
  LabeledFacet,
  LabeledFacetBucket, NO_FILTERS, SuggestionSelection,
} from '@app/modules/dataset/models/dataset-search.model';
import {BehaviorSubject, combineLatest, filter, Observable, Subject, switchMap} from 'rxjs';
import {map, skip, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {
  DatasetSearchFormMapperService
} from '@app/modules/dataset/mappers/dataset-search-form/dataset-search-form-mapper.service';
import {PageComponent} from '@app/modules/core/components/page-component';
import {RumService} from '@app/modules/rum/rum.service';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {DropDownMenuItem} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.model";
import {
  Criteria,
  DATASET_SEARCH_CRITERIA,
  DatasetSearchCriteria,
  DatasetSearchDropDowns,
  DatasetSearchFacets
} from "@app/modules/dataset/pages/datasets-search/datasets-search.model";

import {BucketKey} from '@app/modules/dataset/services/dataset-search-mapper.service';
import {DatasetDropdownService} from "@app/modules/dataset/services/dataset-dropdown-service";
import {Chip, ChipGroup} from "@app/modules/ui/modules/filters-group/components/chips-container/chips-container.model";
import {DatasetSearchService} from "@app/modules/dataset/services/dataset-search.service";

const CRITERIA_LABEL = {
  databases: "Database",
  geographies: "Geography",
  activityTypes: "Activity Type",
  units: "Unit",
  isics: "ISIC"
} as Criteria<string>;

@Component({
  templateUrl: './datasets-search.component.html',
  styleUrl: './datasets-search.component.scss',
})
export class DatasetsSearchComponent implements PageComponent, OnDestroy, OnInit {
  readonly pageName = 'datasets-search';

  showResultsDetails = true;
  firstQuerySent = false;

  searchRequest$?: Observable<DatasetSearchRequest>;
  requestPatch$ = new Subject<DatasetSearchRequestPatch>();
  chipGroups$?: Observable<ChipGroup[]>;
  activeFilterCount$ = new BehaviorSubject<number>(0);

  searchResponse$?: Observable<DatasetSearchResponse>;
  facets$?: Observable<DatasetSearchFacets>;
  dropDowns$: Observable<DatasetSearchDropDowns> | undefined;

  searchForm!: UntypedFormGroup;

  private onDestroy$ = new Subject<void>();

  DATABASE_GROUPS = [
    { key: 'LIVE', label: 'Default' },
    { key: 'LATEST', label: 'Latest' } ,
    { key: 'DEPRECATED', label: 'Obsolete' }
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datasetSearchFormMapperService: DatasetSearchFormMapperService,
    private rumService: RumService,
    private dropdownService: DatasetDropdownService,
    private datasetSearchService: DatasetSearchService,
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: ['']
    });

    this.searchRequest$ = this.activatedRoute.queryParamMap.pipe(
      filter((params) => params.keys.length > 0),
      map((params): DatasetSearchRequest =>
        this.datasetSearchFormMapperService.fromQueryParams(params)
    ));

    // Handle special case where no parameters at all are found
    // redirect to default query with preset filters
    this.activatedRoute.queryParamMap
      .pipe(
        takeUntil(this.onDestroy$),
        filter((params) => params.keys.length === 0)
      )
      .subscribe(() => {
        void this.router.navigate(['datasets'], {
          queryParams: this.datasetSearchFormMapperService.toQueryParams({
            searchTerm: '',
            filters: defaultDatasetSearchFilters()
          }),
        });
      });

    // Triggers the real search (on search request coming from route parameters)
    this.searchResponse$ = this.searchRequest$
      .pipe(
        tap((searchRequest) => {
          this.activeFilterCount$.next(countActiveFilters(searchRequest.filters));
          this.rumService.triggerSearchEvent(searchRequest);
        }),
        switchMap((searchRequest) => {
          return this.datasetSearchService.searchDatasets(searchRequest);
        }));

    // Prepare the full dropdown menus with the complete data without facet data.
    const fullDropDowns = this.dropdownService.getDropdowns();

    // Tracks the facets of the currently active request (completing the raw facets with the labels provided by the dropdowns)
    this.facets$ = combineLatest([this.searchResponse$, fullDropDowns])
      .pipe(map(([results, dropDowns]) => {
        const facets = results.facets;
        const isicKeyMap = new Map(dropDowns.isics.map(menu => [menu.value, menu.label]));
        const isicRawFacet = facets.filter(f => f.key === BucketKey.ISIC).shift();
        return {
          databases: facets.filter(f => f.key === BucketKey.DATABASE).shift(),
          geographies: facets.filter(f => f.key === BucketKey.GEOGRAPHY).shift(),
          activityTypes: facets.filter(f => f.key === BucketKey.ACTIVITY_TYPE).shift(),
          units: facets.filter(f => f.key === BucketKey.UNIT).shift(),
          isics: isicRawFacet ? labelFacet(isicRawFacet, isicKeyMap) : undefined,
        } as DatasetSearchFacets;
      }));

    // Adjusts the dropdowns from the raw ones enriching them with appropriate facet data
    this.dropDowns$ = combineLatest([fullDropDowns, this.facets$]).pipe(map(
      ([dropdowns, facets]) =>
        enrichDropdownsWithFacets(dropdowns, facets)));


    // Ensure chips are in sync with the search request and enriched with dropdown
    this.chipGroups$ = combineLatest([this.searchRequest$, fullDropDowns]).pipe(map(
      ([searchRequest, dropdowns]) => {
        const selectedGroups = DATASET_SEARCH_CRITERIA.filter(criteria => searchRequest.filters[criteria].length > 0);
        return selectedGroups.map((criteria) => makeChipGroup(criteria, searchRequest.filters[criteria], dropdowns[criteria]))
      })
    )

    // Apply an updated to the currently active query are reroute with updated params
    combineLatest([this.searchRequest$, this.requestPatch$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([originalRequest, requestUpdate]) => {
        const newRequest = {
          searchTerm: requestUpdate.searchTerm !== undefined ? requestUpdate.searchTerm : originalRequest.searchTerm,
          filters: {
            ...originalRequest.filters
          }
        } as DatasetSearchRequest;
        if (requestUpdate.filters) {
          for (const [key, value] of Object.entries(requestUpdate.filters)) {
            newRequest.filters[key as keyof DatasetSearchFilters] = value;
          }
        }
        void this.router.navigate(['datasets'], {
          queryParams: this.datasetSearchFormMapperService.toQueryParams(newRequest),
          queryParamsHandling: 'merge'
        });
      })

    // Track if this is the first request or not (to adjust the result message)
    this.searchResponse$
      .pipe(skip(1), take(1))
      .subscribe(() => (this.firstQuerySent = true));
  }

  onSuggestionSelection(userQuery: string, suggestionSelection: SuggestionSelection): void {
    const currentSearchTerm = userQuery.toLowerCase();
    const updatedSearchTerm = currentSearchTerm.replace(
      suggestionSelection.originalText.toLowerCase(),
      suggestionSelection.suggestion
    ) ?? ''
    this.requestPatch$.next({
      searchTerm: updatedSearchTerm
    });
  }

  onShowResultsDetailsChange(showResultsDetails: boolean): void {
    this.showResultsDetails = showResultsDetails;
  }

  onFilterSelectionChange(field: DatasetSearchCriteria, newSelection: string[]): void {
    this.requestPatch$.next({
      filters: {
        [field]: newSelection
      }
    });
  }

  removeAllFilters(): void {
    this.requestPatch$.next({
      filters: NO_FILTERS
    });
  }

  doChipGroupChange(group: ChipGroup): void {
    this.onFilterSelectionChange(
      group.key as DatasetSearchCriteria,
      group.items.map(i => i.value));
  }

  labelFor(criteria: keyof Criteria<string>): string {
    return CRITERIA_LABEL[criteria];
  }

  onUserQueryUpdate($event: string): void {
    this.requestPatch$.next({
      searchTerm: $event,
    });
  }
}

function labelBuckets(buckets: FacetBucket[], labelMap: Map<string, string>): FacetBucket[] {
  return buckets.map(b => {
    return {
      ...b,
      label: labelMap.get(b.bucketName),
      facets: b.facets ? b.facets.map(f => labelFacet(f, labelMap)) : undefined
    } as LabeledFacetBucket
  })
}

function enrichDropdownsWithFacets(dropdowns: DatasetSearchDropDowns, facets: DatasetSearchFacets): DatasetSearchDropDowns {
  return {
    databases: mergeMenuWithFacet(dropdowns.databases, facets.databases?.buckets),
    geographies: mergeMenuWithFacet(dropdowns.geographies, facets.geographies?.buckets),
    activityTypes: mergeMenuWithFacet(dropdowns.activityTypes, facets.activityTypes?.buckets),
    units: mergeMenuWithFacet(dropdowns.units, facets.units?.buckets),
    isics: mergeMenuWithFacet(dropdowns.isics, facets.isics ? flattenBuckets(facets.isics.buckets) : []),
  };
}

function mergeMenuWithFacet(menu: DropDownMenuItem[], buckets: FacetBucket[] | undefined) {
  if (!buckets) {
    return menu;
  }
  const countMap = new Map(buckets.map(b => [b.bucketName, b.docCount]));
  return menu.map(item => {
    const newCount = countMap.get(item.value);
    const newStyle = newCount ? "font-weight: bold" : undefined;
    return {
      ...item,
      count: newCount,
      style: newStyle
    }
  }).sort(compareMenuItems);
}

function compareMenuItems(a: DropDownMenuItem, b: DropDownMenuItem): number {
  const aCount = a.count || 0;
  const bCount = b.count || 0;
  return aCount == bCount ? 0 : aCount > bCount ? -1 : 1;
}

function labelFacet(f: Facet, labelMap: Map<string, string>): LabeledFacet {
  return {
    key: f.key,
    buckets: labelBuckets(f.buckets, labelMap)
  } as LabeledFacet;
}

function flattenBuckets(buckets: LabeledFacetBucket[]): LabeledFacetBucket[] {
  return buckets.flatMap(bucket => {
    if (bucket.facets) {
      const descendantBuckets = bucket.facets.flatMap((f: LabeledFacet) => flattenBuckets(f.buckets));
      return [bucket].concat(descendantBuckets);
    } else {
      return [bucket]
    }
  });
}

function countActiveFilters(filters: DatasetSearchFilters) {
  return Object.entries(filters).map((entry) => entry[1].length).reduce((a, b) => a + b, 0);
}

function chipOfMenuItem(menuItem: DropDownMenuItem): Chip {
  return {
    value: menuItem.value,
    label: menuItem.label
  }
}

function makeChipGroup(criteria: DatasetSearchCriteria, values: string [], criteriaMenu: DropDownMenuItem[]) {
  const chips = values
    .flatMap(value => criteriaMenu.filter(menuItem => menuItem.value === value))
    .map(chipOfMenuItem)
  return {
    key: criteria,
    label: CRITERIA_LABEL[criteria],
    items: chips
  } as ChipGroup
}

import {
  Component,
  EventEmitter, Input, Output,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SearchTipsDialogComponent } from '@app/modules/dataset/components/search-tips-dialog/search-tips-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-datasets-search-form',
  templateUrl: './datasets-search-form.component.html',
  styleUrl: './datasets-search-form.component.scss',
})
export class DatasetsSearchFormComponent {

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
  }

  defaultShowResultsDetails = true;
  formGroup = this.formBuilder.group({
    searchTerm: ''
  });

  @Input()
  set userQuery(newUserQuery: string) {
    this.formGroup.get('searchTerm')?.setValue(newUserQuery);
  }

  @Output()
  userQueryUpdate = new EventEmitter();

  @Output()
  showResultsDetailsChange = new EventEmitter<boolean>();


  openSearchHelpDialog(): void {
    this.dialog.open(SearchTipsDialogComponent, {
      width: '60%',
    });
  }

  onShowDetailsChange(event: MatSlideToggleChange): void {
    this.showResultsDetailsChange.emit(event.checked);
  }

  onSubmit(): void {
    const newUserSearch: string = this.formGroup.get('searchTerm')?.value || '';
    this.userQueryUpdate.emit(newUserSearch);
  }
}

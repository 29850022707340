<div class="search" fxLayout="column" fxLayoutAlign=" stretch" *ngIf="searchRequest$ | async as searchRequest">
    <div class="search-form">

      <app-datasets-search-form
        [userQuery]="searchRequest.searchTerm"
        (userQueryUpdate)="onUserQueryUpdate($event)"
        (showResultsDetailsChange)="onShowResultsDetailsChange($event)"
      ></app-datasets-search-form>

      <div *ngIf="dropDowns$ | async as dropDowns">
        <div class="search-bar">
          <app-drop-down-menu [label]="labelFor('geographies')"
                              [menuItems]="dropDowns.geographies"
                              [selection]="searchRequest.filters.geographies"
                              (changed)="onFilterSelectionChange('geographies',$event)"
          ></app-drop-down-menu>

          <app-drop-down-menu [label]="labelFor('databases')"
                              [groups]="DATABASE_GROUPS"
                              [menuItems]="dropDowns.databases"
                              [selection]="searchRequest.filters.databases"
                              (changed)="onFilterSelectionChange('databases',$event)"
          ></app-drop-down-menu>

          <app-drop-down-menu [label]="labelFor('activityTypes')"
                              [menuItems]="dropDowns.activityTypes"
                              [selection]="searchRequest.filters.activityTypes"
                              (changed)="onFilterSelectionChange('activityTypes',$event)"
          ></app-drop-down-menu>

          <app-drop-down-menu [label]="labelFor('units')"
                              [menuItems]="dropDowns.units"
                              [selection]="searchRequest.filters.units"
                              (changed)="onFilterSelectionChange('units',$event)"
          ></app-drop-down-menu>

          <app-drop-down-menu [label]="labelFor('isics')"
            [menuItems]="dropDowns.isics"
            [selection]="searchRequest.filters.isics"
            (changed)="onFilterSelectionChange('isics',$event)"
          ></app-drop-down-menu>
        </div>
      </div>

      <app-chips-container *ngIf="chipGroups$ | async as chipGroups"
                           [chipGroups]="chipGroups"
                           (chipGroupChange)="doChipGroupChange($event)"
                           (clearChips)="removeAllFilters()"
      ></app-chips-container>
    </div>

    <div class="search-results" *ngIf="searchRequest$ | async as searchRequest">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <app-datasets-search-sidebar fxFlex="25" *ngIf="facets$ | async as facets"
                                     [facets]="facets"
                                     [searchRequest]="searchRequest"
                                     (isicSelectionChange)="onFilterSelectionChange('isics',$event)"
        >
        </app-datasets-search-sidebar>
        <app-datasets-search-results fxFlex="100" fxFlexFill *ngIf="searchResponse$"
                                     [searchResponse$]="searchResponse$"
                                     [showResultsDetails]="showResultsDetails"
                                     [firstQuerySent]="firstQuerySent"
                                     (suggestionSelection)="onSuggestionSelection(searchRequest.searchTerm, $event)"
        ></app-datasets-search-results>
      </div>
    </div>
</div>

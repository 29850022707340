app-exchange-table {
  box-sizing: content-box;

  [exchangerefproduct] td,
  [activity] td {
    padding-top: 15px;
  }

  [exchangesubtotal] td {
    padding-bottom: 5px;
    padding-top: 15px;
  }
  
  .top-separator {
    border-top: 7px solid white;
  }

  .contribution.header {
    text-align: center;
    word-wrap: break-word;
  }

  .label {
    max-width: 915px;
  }

}
